import { types, flow, resolveIdentifier, getRoot } from 'mobx-state-tree';
import { chunkProcessor } from 'mobx-utils';
import moment from 'moment';
import { observable } from 'mobx';

import axios from '../common/utils/axios';
import { Project } from './project-model';

const batchQueue = observable.array();

export const ProjectsList = types
  .model('ProjectList', {
    projects: types.map(Project)
  })
  .views(self => ({
    projectsOfCategory(categories) {
      return [...self.projects.values()].filter(p =>
        categories.includes(p.category)
      );
    },
    projectsOfEmployee(employee) {
      return [...self.projects.values()].filter(p => p.isAssigned(employee));
    },
    projectsOfCategoryInMonth(categories, date, opts = {}) {
      const selectedRange = moment.range(
        moment(date).startOf('month'),
        moment(date).endOf('month')
      );
      const categoryFiltered = [...self.projects.values()].filter(p => {
        const projectRange = moment.range(p.start, p.end);
        const matchesCategory =
          categories === 'all' ||
          opts.exclude ^ categories.includes(p.category); // eslint-disable-line no-bitwise
        return matchesCategory && projectRange.overlaps(selectedRange);
      });
      return categoryFiltered;
    },
    projectsOfCustomerInMonth(customer, date, opts = {}) {
      const selectedRange = moment.range(
        moment(date).startOf('month'),
        moment(date).endOf('month')
      );
      const key = opts.billable ? 'billable_customer' : 'customer';
      const customerFiltered = [...self.projects.values()].filter(p => {
        const projectRange = moment.range(p.start, p.end);
        return (
          p[key]?.id === customer.id && projectRange.overlaps(selectedRange)
        );
      });
      return customerFiltered;
    }
  }))
  // TODO: add try/catch error handling to yield functions
  .actions(self => ({
    load: flow(function* load(date, opts = {}) {
      const { loadAssignments = false, ...params } = opts;
      const { assignments } = getRoot(self);
      params.start = date.startOf('month').format('YYYY-MM-DD'); // TODO change these
      params.end = date.endOf('month').format('YYYY-MM-DD');
      try {
        const { data } = yield axios.get('/api/v1/projects', { params });
        data.map(p => self.projects.put(p));
        if (loadAssignments) {
          // TODO: Non-hardcoded filtering
          assignments.batch(
            data
              .filter(p => ['bitfactor', 'customer'].includes(p.category))
              .map(p => p.id)
          );
        }
      } catch (error) {
        console.log('Error loading project list', error); // eslint-disable-line no-console
      }
      return self;
    }),
    batchLoad: flow(function* batchLoad(ids) {
      const { data } = yield axios.get(
        `/api/v1/projects?projects=${JSON.stringify(ids)}`
      );
      data.map(p => self.projects.put(p));
    }),
    loadProject(id, batch = false) {
      const project = resolveIdentifier(Project, self, id);
      if (project) {
        return project;
      }
      const p = Project.create({ id });
      if (batch) {
        batchQueue.push(p.id);
      } else {
        p.load(id);
      }
      self.projects.put(p);
      return p;
    },
    addProject: flow(function* addProject(project, skip_common) {
      const { data } = yield axios.post(
        '/api/v1/projects',
        JSON.stringify({ project, skip_common })
      );
      self.projects.put(data);
      return data;
    }),
    deleteProject: flow(function* deleteProject(id) {
      self.projects.delete(id);
      yield axios.delete(`/api/v1/projects/${id}`);
    }),
    getIds() {
      return [...self.projects.keys()].map(i => parseInt(i, 10));
    },
    afterCreate() {
      chunkProcessor(batchQueue, data => self.batchLoad(data), 50, 0);
    },
    newProjectForCustomer(customer) {
      const project =
        resolveIdentifier(Project, self, -1) ||
        Project.create({
          id: -1,
          name: 'New Project',
          category: 'customer',
          customer: {
            id: customer.id,
            name: customer.name,
            managers: customer.managerIds
          }
        });
      project.changeTab('edit');
      self.projects.put(project);
      return self;
    },
    resetNewProject() {
      self.projects.delete(-1);
    }
  }));

export const sortByName = projects => {
  return projects.sort((a, b) => a.name.localeCompare(b.name));
};

export default ProjectsList;
