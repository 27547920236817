import {
  flow,
  types,
  applySnapshot,
  destroy,
  detach,
  getSnapshot
} from 'mobx-state-tree';
import moment from 'moment';
import { withValidations } from 'mst-validatejs';

import { calculateDateRangeQuery, dateValues } from '../common/utils/time';
import axios from '../common/utils/axios';
import Decimal, { MomentType } from './types';
import {
  EmployeeTimesheet,
  TimeEntrySummary
} from './employee-timesheet-model';
import { EmployeeCv } from './cv/cv-model';
import CvVersions from './cv/cv-versions-model';

import { Profile } from './profile-model';
import { EmployeeLogs } from './employee-log-model';
import { Equipment } from './equipment-model';
import { Tag } from './tag-list-model';
import { EmployeeOnboarding } from './employee-onboarding-model';
import notificationStore from '../stores/notificationStore';

/* Replace Google API's default 96px crop parameter for avatar,
   during the transition time to our own AWS avatar usage */
const replaceAvatarCrop = e => {
  const crop = 's96-c';
  if (e.avatar && e.avatar.endsWith(crop)) {
    e.avatar = e.avatar.replace(crop, 's500-c');
  }
  return e;
};

const EmployeeSummary = types
  .model('EmployeeSummary', {
    missing_days: types.optional(types.integer, 30),
    balance: types.optional(Decimal, 0),
    overtime: types.optional(Decimal, 0),
    holiday: types.optional(Decimal, 0),
    sickleave: types.optional(Decimal, 0),
    unpaid: types.optional(Decimal, 0),
    reported: types.optional(Decimal, 0)
  })
  .actions(self => ({
    update: data => {
      Object.keys(data).forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (self.hasOwnProperty(key)) {
          self[key] = data[key]; // eslint-disable-line no-param-reassign
        }
      });
    }
  }));

export const EmployeeBalanceCorrection = types
  .model('EmployeeBalanceCorrection', {
    id: types.identifierNumber,
    hours: types.optional(Decimal, 0),
    day: types.optional(MomentType, moment())
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .extend(
    withValidations({
      day: {
        validDate: true
      },
      hours: {
        numericality: true
      }
    })
  )
  .actions(self => ({
    setHours(h) {
      self.hours = h; // eslint-disable-line no-param-reassign
    },
    setDay(d) {
      self.day = d; // eslint-disable-line no-param-reassign
    }
  }));

const bonusTypeMap = {
  revenue_based: '70 / 30',
  billing_rate_based: 'Static',
  no_bonus: 'No bonus'
};

export const EmployeeAllocation = types
  .model('EmployeeAllocation', {
    id: types.identifierNumber,
    start_date: types.optional(MomentType, moment()),
    bonus_type: types.optional(
      types.enumeration(Object.keys(bonusTypeMap)),
      'revenue_based'
    ),
    allocation: types.optional(Decimal, 0)
  })
  .postProcessSnapshot(snapshot => {
    delete snapshot.id; // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .extend(
    withValidations({
      start_date: {
        validDate: true
      },
      allocation: {
        numericality: true
      }
    })
  )
  .views(self => ({
    get bonusType() {
      return bonusTypeMap[self.bonus_type];
    }
  }))
  .actions(self => ({
    setAllocation(allocation) {
      self.allocation = allocation; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start_date = date; // eslint-disable-line no-param-reassign
    }
  }));

const EmployeeFeedback = types.model('EmployeeFeedback', {
  value: types.integer,
  comment: types.string,
  created_at: MomentType
});

const EmployeeSalaryData = types.model('EmployeeSalaryData', {
  base_salary: Decimal,
  base_salary_with_flex: Decimal,
  bonus_accumulation: Decimal,
  personal_revenue_total: Decimal,
  company_share_total: Decimal,
  total_projected_hours: Decimal
});

const EmployeeAbsence = types.model('EmployeeAbsence', {
  id: types.identifierNumber,
  reason: types.string,
  until: MomentType,
  created_at: MomentType,
  updated_at: MomentType
});

export const Employee = types
  .model('Employee', {
    id: types.identifierNumber,
    proposition_id: types.maybeNull(types.number, null),
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    avatar: types.maybeNull(types.string),
    role: types.optional(types.string, ''),
    email: types.maybeNull(types.string),
    cost_center: types.optional(types.string, ''),
    location: types.optional(types.string, ''),
    profile: types.optional(Profile, () => Profile.create()),
    start: types.maybeNull(MomentType),
    suspended: types.maybeNull(MomentType),
    created_at: types.maybeNull(MomentType),
    last_login: types.maybeNull(MomentType),
    absence: types.maybeNull(types.union(EmployeeAbsence, types.boolean)),
    project_end: types.maybeNull(MomentType),
    company_id: types.maybeNull(types.number),
    cv: types.optional(EmployeeCv, () => EmployeeCv.create({ id: -1 })),
    cvVersions: types.map(CvVersions),
    cvs: types.array(EmployeeCv),
    summaries: types.map(EmployeeSummary),
    timesheets: types.map(EmployeeTimesheet),
    feedbacks: types.optional(types.array(EmployeeFeedback), []),
    salary_data: types.maybeNull(EmployeeSalaryData),
    balance_corrections: types.map(EmployeeBalanceCorrection),
    allocations: types.array(EmployeeAllocation),
    phone_benefit: types.optional(types.boolean, true),
    bicycle_benefit: types.optional(types.boolean, false),
    car_benefit: types.optional(types.boolean, false),
    tags_count: types.optional(types.number, 0),
    rotation: types.maybeNull(
      types.enumeration(['no_interest', 'interested', 'critical']),
      'no_interest'
    ),
    tags: types.map(Tag),
    tag_ids: types.array(types.number), // TODO: replace tags map with this everywhere
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    ),
    hrm_logs: types.optional(EmployeeLogs.props({ type: 'hrm' }), {}),
    sales_logs: types.optional(EmployeeLogs.props({ type: 'sales' }), {}),
    mentoring_logs: types.optional(
      EmployeeLogs.props({ type: 'mentoring' }),
      {}
    ),
    equipment: types.optional(Equipment, {}),
    onboarding: types.optional(EmployeeOnboarding, () =>
      EmployeeOnboarding.create({ id: -1 })
    ),
    deletable: types.optional(types.boolean, true)
  })
  .preProcessSnapshot(snapshot => {
    snapshot = replaceAvatarCrop(snapshot); // eslint-disable-line no-param-reassign
    return snapshot;
  })
  .extend(
    withValidations({
      first_name: {
        length: { minimum: 1, message: 'First name is required' }
      },
      last_name: {
        length: { minimum: 1, message: 'Last name is required' }
      },
      role: {
        length: { minimum: 1, message: 'Role is required' }
      },
      email: {
        length: { minimum: 1, message: 'Email is required' },
        format: {
          pattern: /\b[a-z][\w.!#$%&’*+/=?^`{|}~-]+@[\w-]+(?:\.[\w-]+)*\b/,
          message: 'Invalid email address.'
        }
      }
    })
  )
  .volatile(() => ({
    collapsed: true
  }))
  .views(self => ({
    get balanceCorrections() {
      return [...self.balance_corrections.values()];
    },
    get isExternal() {
      return ['external', 'freelance'].includes(self.role);
    },
    summary(month = moment()) {
      const { start } = calculateDateRangeQuery(month);
      let s = self.summaries.get(start);
      if (!s) {
        s = self.addSummary({}, month);
      }
      return s;
    },
    timesheetFor(month) {
      const { start } = calculateDateRangeQuery(month);
      const s = this.timesheets.get(start);
      if (!s) {
        return this.loadTimesheet(month);
      }
      return s;
    },
    isActive(month, exactDate = false) {
      const end = month.endOf(exactDate ? 'day' : 'month');
      if (!self.suspended || !self.created_at) {
        return true;
      }
      return (
        self.created_at.isSameOrBefore(end) &&
        end.isSameOrBefore(
          moment(self.suspended)
            .clone()
            .endOf(exactDate ? 'day' : 'month')
        )
      );
    },
    isPayrollActive(month) {
      const end = month.endOf('month');
      const endWithSubtractedMonth = moment(month)
        .subtract(1, 'months')
        .endOf('month');
      if (!self.suspended || !self.created_at) {
        return true;
      }
      return (
        self.created_at.isSameOrBefore(end) &&
        endWithSubtractedMonth.isSameOrBefore(
          moment(self.suspended)
            .clone()
            .endOf('month')
        )
      );
    },
    isReady() {
      return self.state === 'ready';
    },
    fullName() {
      return `${self.last_name} ${self.first_name}`;
    },
    isAdmin() {
      return ['admin', 'finance'].includes(self.role);
    },
    hasTag(tagId) {
      return self.tags.has(tagId);
    },
    allTags() {
      return [...self.tags.values()];
    },
    isSocialEmail() {
      // TODO: Extend definition later
      return self.email?.endsWith('@gmail.com');
    },
    isCompanyEmail() {
      const { domain } = JSON.parse(localStorage.getItem('settings') || '{}');
      return self.email?.endsWith(domain);
    },
    hasCvLocale(locale, name) {
      return [self.cv, ...self.cvs].some(
        cv => cv.locale === locale && (name ? cv.name === name : cv.default)
      );
    },
    getCvNames() {
      const allNames = [self.cv, ...self.cvs]
        .sort((a, b) => (a.id === -1 ? 1 : a.id - b.id))
        .map(cv => cv.name);

      return [...new Set(allNames)];
    },
    hasInitializedCv() {
      return self.cvs.some(cv => cv.id === -1);
    }
  }))
  .actions(self => ({
    setCollapsed(collapse) {
      self.collapsed = collapse; // eslint-disable-line no-param-reassign
    },
    load: flow(function* load() {
      if (self.state === 'ready') {
        return self;
      }
      try {
        const { data } = yield axios.get(`/api/v1/employees/${self.id}`);
        applySnapshot(self, data);
        self.state = 'ready'; // eslint-disable-line no-param-reassign
      } catch (error) {
        console.error('Failed to fetch employee', error); // eslint-disable-line no-console
        self.state = 'error'; // eslint-disable-line no-param-reassign
      }
      return self;
    }),
    update(data) {
      Object.keys(data).forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (self.hasOwnProperty(key)) {
          self[key] = data[key]; // eslint-disable-line no-param-reassign
        }
      });
    },

    setState(status) {
      self.state = status; // eslint-disable-line no-param-reassign
    },

    setStart: flow(function* setStart(timestamp) {
      const { data } = yield axios.put(
        `/api/v1/employees/${self.id}`,
        JSON.stringify({ start: timestamp })
      );
      self.start = data.start; // eslint-disable-line no-param-reassign
    }),

    setSuspended: flow(function* setSuspended(timestamp) {
      const { data } = yield axios.put(
        `/api/v1/employees/${self.id}`,
        JSON.stringify({
          suspended: moment(timestamp).format('YYYY-MM-DD')
        })
      );
      self.suspended = data.suspended; // eslint-disable-line no-param-reassign
    }),
    setBenefit: flow(function* setBenefit(benefit, value) {
      const { data } = yield axios.put(
        `/api/v1/employees/${self.id}`,
        JSON.stringify({ [benefit]: value })
      );
      self[benefit] = data[benefit]; // eslint-disable-line no-param-reassign
    }),

    setPhoneBenefit(value) {
      return self.setBenefit('phone_benefit', value);
    },
    setBicycleBenefit(value) {
      return self.setBenefit('bicycle_benefit', value);
    },
    setCarBenefit(value) {
      return self.setBenefit('car_benefit', value);
    },

    setRotation: flow(function* setRotation(rotation) {
      const { data } = yield axios.put(
        `/api/v1/employees/${self.id}`,
        JSON.stringify({ rotation })
      );
      self.rotation = data.rotation; // eslint-disable-line no-param-reassign
    }),

    setLocation: flow(function* setLocation(value) {
      const oldValue = self.location;
      self.location = value; // eslint-disable-line no-param-reassign
      try {
        yield axios.put(
          `/api/v1/employees/${self.id}`,
          JSON.stringify({ location: value })
        );
      } catch (error) {
        console.error('Error saving employee location', error); // eslint-disable-line no-console
        self.location = oldValue; // eslint-disable-line no-param-reassign
      }
    }),

    setEmail(email) {
      self.email = email.trim(); // eslint-disable-line no-param-reassign
    },

    setFirstName(name) {
      self.first_name = name; // eslint-disable-line no-param-reassign
    },

    setLastName(name) {
      self.last_name = name; // eslint-disable-line no-param-reassign
    },

    setRole: flow(function* setRole(role, save) {
      self.role = role; // eslint-disable-line no-param-reassign
      if (save) {
        yield axios.put(
          `/api/v1/employees/${self.id}`,
          JSON.stringify({ role })
        );
      }
    }),
    setCompanyId(company_id) {
      self.company_id = company_id; // eslint-disable-line no-param-reassign
    },

    loadProjects: flow(function* loadProjects() {
      const { start, end } = calculateDateRangeQuery(moment());
      const { data } = yield axios.get(
        `/api/v1/projects?start=${start}&end=${end}`
      );
      return data;
    }),

    loadSummary: flow(function* loadSummary(month) {
      const { start, end } = calculateDateRangeQuery(month);
      const e = JSON.stringify([self.id]);
      const { data } = yield axios.get(
        `/api/v1/employees/report?start=${start}&end=${end}&employees=${e}`
      );
      const s = self.addSummary(data[0], month);
      return s;
    }),

    loadBalanceCorrections: flow(function* loadBalanceCorrections() {
      const { data } = yield axios.get(`/api/v1/employees/${self.id}/balance`);
      data.forEach(b => self.balance_corrections.put(b));
    }),

    loadAllocations: flow(function* loadAllocations() {
      const { data } = yield axios.get(
        `/api/v1/employees/${self.id}/allocations`
      );
      // eslint-disable-next-line no-param-reassign
      self.allocations = data;
    }),

    addSummary(summary, month = moment()) {
      const { start } = calculateDateRangeQuery(month);
      let s = self.summaries.get(start);
      if (s) {
        s.update(summary);
      } else {
        s = EmployeeSummary.create(summary);
        self.summaries.set(start, s);
      }
      return s;
    },
    loadTimesheet: flow(function* loadTimesheet(m) {
      const { start } = calculateDateRangeQuery(m);
      const { month, year } = dateValues(m);
      const { data } = yield axios.get(
        `/api/v1/employees/${self.id}/timesheet.json?month=${month}&year=${year}`
      );
      const entries = data.timesheet
        .concat(data.missing_time_entries)
        .sort((a, b) => (moment(a.day).isBefore(b.day) ? -1 : 1))
        .map(e => TimeEntrySummary.create(e));

      const capacity = parseFloat(data.capacity);
      const result = EmployeeTimesheet.create({ capacity, entries });
      self.timesheets.set(start, result);
      return result;
    }),

    addBalanceCorrection: flow(function* addBalanceCorrection(correction) {
      const model = EmployeeBalanceCorrection.create({ ...correction, id: -1 });
      const { data } = yield axios.post(
        `/api/v1/employees/${self.id}/balance`,
        JSON.stringify(model)
      );
      self.balance_corrections.put(data);
      self.loadSummary(moment());
      return data;
    }),

    addAllocation: flow(function* addAllocation(allocation) {
      // eslint-disable-next-line no-unused-vars
      const { data } = yield axios.post(
        `/api/v1/employees/${self.id}/allocations`,
        JSON.stringify(EmployeeAllocation.create({ ...allocation, id: -1 }))
      );
      self.loadAllocations();
      self.loadSummary(moment());
      return data;
    }),

    loadFeedback: flow(function* loadFeedback() {
      let loaded = [];
      try {
        const { data } = yield axios.get(
          `/api/v1/employees/${self.id}/feedback`
        );
        // For some yet unknown reason if the model array (self.feedbacks) is returned
        // the data might not be 'available' correctly in the promise.
        loaded = data;
        // eslint-disable-next-line no-param-reassign
        self.feedbacks = data.map(f =>
          EmployeeFeedback.create({
            value: f.value,
            created_at: f.created_at,
            comment: f.comment
          })
        );
      } catch (error) {
        console.error('Failed to fetch feedback.', error); // eslint-disable-line no-console
      }
      return loaded;
    }),

    addFeedback: flow(function* addFeedback(postData) {
      try {
        const { data } = yield axios.post(
          `/api/v1/employees/${self.id}/feedback`,
          JSON.stringify(postData)
        );
        self.feedbacks.unshift(data);
        return true;
      } catch (error) {
        console.error('Failed to send feedback.', error); // eslint-disable-line no-console
        return false;
      }
    }),

    loadSalaryData: flow(function* loadSalaryData(
      month,
      is_projection = false
    ) {
      try {
        const params = is_projection ? { is_projection } : {};
        const { start, end } = calculateDateRangeQuery(month, true);
        const {
          data
        } = yield axios.get(
          `/api/v1/employees/${self.id}/bonus?start=${start}&end=${end}`,
          { params }
        );
        // eslint-disable-next-line no-param-reassign
        self.salary_data = EmployeeSalaryData.create({
          base_salary: parseFloat(data.base_salary),
          base_salary_with_flex: parseFloat(data.base_salary_with_flex),
          bonus_accumulation: parseFloat(data.bonus_accumulation),
          personal_revenue_total: parseFloat(data.personal_revenue_total),
          company_share_total: parseFloat(data.company_share_total),
          total_projected_hours: parseFloat(data.total_projected_hours)
        });
      } catch (error) {
        console.error('Failed to fetch salary data.', error); // eslint-disable-line no-console
      }
    }),

    updateAvatar: flow(function* updateAvatar(avatar) {
      const d = { avatar };
      try {
        const { data } = yield axios.put(`/api/v1/employees/${self.id}/`, d);
        self.avatar = data.avatar; // eslint-disable-line no-param-reassign
      } catch (error) {
        notificationStore.queue('Failed to update avatar.');
        console.error('Failed to update avatar.', error); // eslint-disable-line no-console
      }
    }),
    loadTags: flow(function* loadTags() {
      try {
        const params = { model_type: 'employee', model_id: self.id };
        const { data } = yield axios.get('/api/v1/tags', { params });
        data.map(tag => self.tags.put(tag));
      } catch (error) {
        console.error('Failed to load employee tags', error); // eslint-disable-line no-console
      }
    }),
    addTags: flow(function* addTags(tag_ids) {
      try {
        const params = { model_type: 'employee', model_id: self.id, tag_ids };
        const { data } = yield axios.post('/api/v1/tags/add', params);
        data.forEach(tag => self.tags.put(tag));
      } catch (error) {
        console.error('Could not add tags', error); // eslint-disable-line no-console
      }
      return self;
    }),
    toggleTag: flow(function* toggleTag(tagId) {
      try {
        const params = { model_type: 'employee', model_id: self.id };
        const { data } = yield axios.put(
          `/api/v1/tags/${tagId}/toggle`,
          params
        );
        if (self.hasTag(tagId)) {
          destroy(self.tags.get(tagId));
        } else {
          self.tags.put(data);
        }
      } catch (error) {
        console.error('Could not toggle tag', error); // eslint-disable-line no-console
      }
      return self;
    }),
    revertCv(cv, timestamp) {
      const versions = self.cvVersions.get(cv.id);

      const reversion = versions.list.find(version =>
        version.data.updated_at.isSame(timestamp)
      );

      const { updated_at, ...data } = getSnapshot(reversion.data);

      applySnapshot(cv, { ...cv, ...data, state: 'pending' }); // Resets editor
      cv.save();
      destroy(versions); // Reset versions
    },
    setOnboarding(onboarding) {
      detach(self.onboarding);
      self.onboarding = onboarding; // eslint-disable-line no-param-reassign
    },
    loadCvs: flow(function* loadCvs() {
      try {
        const { data } = yield axios.get(`/api/v1/employees/${self.id}/cvs`);
        self.cvs = data.map(cv => ({ ...cv, state: 'ready' })); // eslint-disable-line no-param-reassign
      } catch (error) {
        console.error('Failed to load cv locales', error); // eslint-disable-line no-console
      }
    }),
    loadCvVersions({ id }) {
      const versions = self.cvVersions.get(id) || self.cvVersions.put({ id });
      versions.load();

      return versions;
    },
    getCv(locale, name, forceInitialize = false) {
      const allCvs = [self.cv, ...self.cvs];
      const byName = allCvs.filter(cv => cv.name === name);
      const byNameAndLocale = byName.find(cv => cv.locale === locale);

      return (
        byNameAndLocale ||
        (forceInitialize ? self.initializeCv(locale, name) : byName[0])
      );
    },
    initializeCv(locale, name = 'New CV') {
      const cv = EmployeeCv.create({
        id: -1,
        locale,
        name,
        editable_name: name,
        state: 'ready'
      });
      return self.cvs.push(cv) && cv;
    },
    removeInitializedCv() {
      const fresh = self.cvs.find(cv => cv.id === -1);
      if (fresh) {
        destroy(fresh);
      }
    },
    removeCv: flow(function* removeCv(removed, multi_locale) {
      const params = { multi_locale };
      try {
        yield axios.delete(`/api/v1/employees/${self.id}/cvs/${removed.id}`, {
          params
        });
        if (multi_locale) {
          [...self.cvs].filter(cv => cv.name === removed.name).map(destroy);
        } else {
          destroy(removed);
        }
      } catch (error) {
        console.error('deleting cv failed', error); // eslint-disable-line no-console
      }
      return self;
    })
  }));

export const sortByDate = balanceCorrections =>
  balanceCorrections.sort((a, b) => (a.day.isAfter(b.day) ? -1 : 1));

export const sortAllocationsByDate = allocations =>
  allocations
    .slice()
    .sort((a, b) => (a.start_date.isAfter(b.start_date) ? -1 : 1));

export default Employee;
